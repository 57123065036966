import React from "react";
import { Row, Col, FormGroup, Button, Container } from "reactstrap";
import "./loginstyle.scss";
import { Link } from "react-router-dom";
import EPIBSBGImage from "../../assets/images/ebips-assets/Ebips-image.png";
import EPIBSLogo from "../../assets/images/ebips-assets/ebips-logo.svg";
// import faan from "../../assets/images/faan.png";
import { commonActions, alertActions } from "../../_actions";
import { appConstants, alertConstants } from "../../_constants";
import { commonService } from "../../_services";
import { history } from "../../jwt/_helpers";
import { connect } from "react-redux";
import axios from "axios";
import { store } from "react-notifications-component";
import { appHelpers } from "../../_helpers";
import { AppProgressLoader } from "../../components/AppProgressLoader";
import FullScreenModal from "../../components/FullScreenModal";
import PrivacyPolicy from "../policies/privacy-policy";
import CookiesConsent from "../policies/cookies-consent";
let source;

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // this.props.dispatch(commonActions.logOut());

    this.state = {
      username: "",
      password: "",
      isRequesting: false,
      error: false,
      componentErrorMessages: [],
      componentError: false,
      year: "",
      domainURL: "",
      showPrivacyPolicy: false,
      cookieName: "bdaweb:cc",
      // height: window.innerHeight
      // btn: <div>Sign In</div>
    };
    //this.validateInput = this.validateInput.bind(this);
    source = axios.CancelToken.source();
  }

  componentDidMount() {
    //Get copyright year
    let date = new Date();
    let year = date.getFullYear();
    this.setState({ year });

    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";

    const { dispatch } = this.props;

    if (appHelpers.getSessionErrorMessage()) {
      this.setState({ componentError: appHelpers.getSessionErrorMessage() });
      appHelpers.destroySessionExpired();
      appHelpers.destroySession();
    }

    if (alert) {
      if (alert.type === alertConstants.SUCCESS) {
        this.setState({ error: false, success: alert.message });
      } else if (alert.type === alertConstants.CLEAR) {
        this.setState({ success: false, error: false });
      } else if (alert.type === alertConstants.ERROR) {
        this.setState({ success: false, error: alert.message });
      }
    }
    // dispatch(alertActions.resetApp());
    dispatch(commonActions.logOut());

    /////-----------//////////
    /////-----------//////////
    // when user hits the login page,
    // immediately call the getBrandingPath endpoint,
    // posting the domain url to it and
    // set the result of the endpoint in userDataReducer

    appHelpers.getBrandingFromDomainURL.call(this);
    this.toggleCookieDisplay();
  }

  logOut = () => {
    // window.location.href = '/login'
    this.props.dispatch(commonActions.logOut());
  };

  componentWillReceiveProps = function (nextProps) {
    const { alert } = nextProps;
    // const {dispatch} = this.props;
    if (alert) {
      if (alert.type === alertConstants.SUCCESS) {
        this.setState({ error: false, success: alert.message });
      } else if (alert.type === alertConstants.CLEAR) {
        this.setState({ success: false, error: false });
      } else if (alert.type === alertConstants.ERROR) {
        this.setState({ success: false, error: alert.message });
      }
    }
  };

  handleChange = (event, attribute) => {
    let currState = this.state;
    currState[attribute] = event.target.value;
    let errorMessages = this.state.componentErrorMessages;
    errorMessages[attribute] = false;
    this.setState(currState);
    this.setState({ componentErrorMessages: errorMessages });
  };

  validateInput = () => {
    let _errs_ = [];
    let formValid = true;
    if (this.state.username === "") {
      _errs_["username"] = "Please enter email";
      formValid = false;
    }
    if (this.state.password === "") {
      _errs_["password"] = "Please enter password";
      formValid = false;
    }
    this.setState({ componentErrorMessages: _errs_ });
    return formValid;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.resetError();
    if (this.validateInput()) {
      this.setState({ isRequesting: true });
      this.processSubmit();
      // } else {
      //   this.setState({ componentError: 'Provide valid details' })
    }
  };

  processSubmit = () => {
    const { username, password, domainURL } = this.state;
    const { dispatch } = this.props;
    let payload = { userName: username, password: password, 
      domainURL: process.env.NODE_ENV === "development" ? "" : domainURL, };

    commonService.login(payload, source.token).then(
      (response) => {
        this.setState({ isRequesting: false });
        if (response.status === appConstants.SUCCESS_RESPONSE) {
          let user = response.response;
          dispatch(success(user));
          dispatch(commonActions.saveUserData(user));

          let payload = {
            agencyId: user.organizationId,
            agencyCode: user.organizationCode,
            domainURL: appHelpers.getDomainOrigin(),  
          };

          dispatch(commonActions.getBranding(payload, user));
          dispatch(commonActions.setWelcomeScreenVisibility(true));
          const { routes } = user;

          let firstRoute = routes[0];
          let baseRoute = firstRoute.path;

          history.push(baseRoute);
        } else {
          store.addNotification({
            message: response.response,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              // onScreen: true
            },
          });
          // dispatch(failure(response.response));
          // dispatch(alertActions.stopRequest());
        }
      },
      (error) => {
        let errorMessage = error.response.data
          ? error.response.data
          : "Unable to handle request";
        dispatch(failure(errorMessage));
        this.setState({ isRequesting: false });
        // dispatch(alertActions.stopRequest());
      }
    );

    function success(user) {
      return { type: appConstants.SIGN_IN_SUCCESS, user };
    }
    function failure(message) {
      return { type: appConstants.ERROR, message };
    }
  };

  resetError() {
    this.props.dispatch(alertActions.clear());
  }

  renderPageResponse = () => {
    if (this.state.error) {
      return <div message={this.state.error} />;
      // return (<div className="alert alert-danger text-capitalize-first">{this.state.error}</div>);
    } else if (this.state.componentError) {
      return (
        <div className="alert alert-danger text-capitalize-first">
          {this.state.componentError}
        </div>
      );
    } else if (this.state.success) {
      return (
        <div className="alert alert-success text-capitalize-first">
          {this.state.success}
        </div>
      );
    }
  };

  showPrivacyPolicy = () => {
    this.setState({ showPrivacyPolicy: true });
  };

  dismissPrivacyPolicyModal = (response) => {
    this.setState({ showPrivacyPolicy: response });
  };

  //cookies
  setCookies = (name, value, days) => {
    let expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
    this.toggleCookieDisplay();
  };

  getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  toggleCookieDisplay = () => {
    const { cookieName } = this.state;
    let cookieObj = this.getCookie(cookieName);

    let cookieWrapperElement = document.querySelector(".cookies-poilcy-footer");
    if (cookieObj === null) {
      cookieWrapperElement.style.display = "flex";
    } else {
      cookieWrapperElement.style.display = "none";
    }
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    let pageResponse = this.renderPageResponse();
    const {
      isRequesting,
      componentErrorMessages,
      year,
      showPrivacyPolicy,
      cookieName,
    } = this.state;
    const { userData } = this.props;

    let backgroundImage = userData.backgroundUrl
      ? userData.backgroundUrl
      : EPIBSBGImage;
    let logo = userData.logoUrl ? userData.logoUrl : EPIBSLogo;
    return (
      <div>
        {this.props.appRequesting === "start app request" && (
          <AppProgressLoader
            message={"Sending Request"}
            displayLoaderFullOverlay
          />
        )}
        <div
          style={{
            background: `linear-gradient(rgba(4, 36, 65, 0.5) 20%, rgba(7, 52, 91, 0.5) 90%),url(${backgroundImage})`,
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
          }}
          className={"login-container"}
        >
          <Container>
            <Row className={"login-row"}>
              <Col lg={6} md={12}>
                <div className={"login-header-container "}>
                  <h1
                    className="headerText "
                    style={{
                      fontWeight: "600",
                      marginTop: "5px",
                      fontSize: "25px",
                    }}
                  >
                    Electronic Billing, Invoicing <br /> and Payment Solution
                  </h1>
                  <p
                    className="baseText"
                    style={{ fontSize: "11px", fontWeight: "300" }}
                  >
                    Improve transparency and accountability and streamline{" "}
                    <br /> your invoice-to-Cash Operations across all Payment
                    Methods <br /> and Channel on a Single Platform.
                  </p>
                </div>
              </Col>
              <Col sm={12} lg={6}>
                <div
                  className="card o-hidden border-0 shadow-lg "
                  id="cardLogin"
                >
                  <div className="card-body card-control p-0">
                    <Row>
                      <img
                        src={logo}
                        alt="BDA Logo"
                        style={{
                          verticalAlign: "middle",
                          borderStyle: "none",
                          width: "25%",
                          marginLeft: "40%",
                          marginTop: "40px",
                        }}
                      />
                      <div className="col-lg-12">
                        <div className="p-9" style={{ marginTop: "15%" }}>
                          <div className="text-center" />
                          <form className="user">
                            <center>
                              {pageResponse}
                              <FormGroup
                                id="loginForm"
                                // onSubmit={this.handleSubmit}
                              >
                                <input
                                  size="lg"
                                  type="username"
                                  name="username"
                                  id="exampleInputUsername"
                                  _errs_={componentErrorMessages.username}
                                  // aria-describedby="usernameHelp"
                                  value={this.state.username}
                                  placeholder="Email"
                                  onChange={(event) =>
                                    this.handleChange(event, "username")
                                  }
                                />
                                {componentErrorMessages.username && (
                                  <span className={"inputError"}>
                                    {componentErrorMessages.username}
                                  </span>
                                )}
                                {/* <span className="bar"></span> */}
                              </FormGroup>
                              <FormGroup>
                                <input
                                  size="lg"
                                  type="password"
                                  name="password"
                                  value={this.state.password}
                                  id="exampleInputPassword"
                                  _errs_={componentErrorMessages.password}
                                  placeholder="Password"
                                  onChange={(event) =>
                                    this.handleChange(event, "password")
                                  }
                                />
                                {componentErrorMessages.password && (
                                  <span className={"inputError"}>
                                    {componentErrorMessages.password}
                                  </span>
                                )}
                                {/* <span className="bar"></span> */}
                              </FormGroup>
                            </center>
                            <div
                              className={"d-flex justify-content-space-between"}
                            >
                              <div>
                                <p
                                  style={{ marginBottom: 0 }}
                                  className="policy"
                                  onClick={() => this.showPrivacyPolicy()}
                                >
                                  Privacy Policy
                                </p>
                                <Link
                                  className="policy"
                                  to="/customer-awarness-policy"
                                >
                                  Customer awareness policy
                                </Link>
                              </div>

                              <p
                                className="login-forgot-password"
                                style={{
                                  textAlign: "right",
                                  paddingRight: "2.7rem",
                                  marginTop: "0",
                                }}
                              >
                                <a
                                  //  onClick={() => {
                                  //    history.push('/forgot-password')
                                  //  }}
                                  href="/forgot-password"
                                  style={{
                                    color: "#000000",
                                    fontSize: "10px",
                                  }}
                                >
                                  Forgot Password?
                                </a>
                                {""}
                              </p>
                            </div>
                            <div></div>
                            <div className={"text-center"}>
                              <Button
                                color={"success"}
                                onClick={this.handleSubmit}
                                id="loginButton"
                                className={
                                  "btn-rounded ml-3 mb-2 px-4 loginBtn"
                                }
                                disabled={isRequesting}
                              >
                                {isRequesting ? (
                                  <span>
                                    Signing in...{" "}
                                    <i className="fa fa-spinner fa-spin"></i>
                                  </span>
                                ) : (
                                  `Sign in`
                                )}

                                {this.state.btn}
                              </Button>
                            </div>

                            <center>
                              {/* <p
                                style={{
                                  marginTop: "8px",
                                  fontSize: "10px",
                                  marginBottom: "0.7rem",
                                }}
                              > */}
                              {/* <span
                                className="policy mr-2"
                                onClick={() => this.showPrivacyPolicy()}
                              >
                                Privacy Policy
                              </span>
                              <span>|</span>
                              <Link
                                className="policy ml-2"
                                to="/customer-awarness-policy"
                              >
                                Customer awareness policy
                              </Link> */}
                              {/* </p> */}
                              <p
                                style={{
                                  fontWeight: "bold",
                                  marginBottom: "0.1rem",
                                  marginTop: "15px",
                                  fontSize: "10px",
                                }}
                              >
                                Federal Ministry of Aviation, Nigeria
                              </p>
                              <p style={{ marginTop: "0", fontSize: "10px" }}>
                                {`AVITECH All Rights Reserved, Copyright \u00A9 ${year}`}{" "}
                              </p>
                            </center>
                          </form>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <CookiesConsent
            setCookies={this.setCookies}
            cookieName={cookieName}
          />
          {showPrivacyPolicy && (
            <FullScreenModal
              show={showPrivacyPolicy}
              callback={this.dismissPrivacyPolicyModal}
              zIndex={100}
              width={"70%"}
              left={"0%"}
            >
              <PrivacyPolicy />
            </FullScreenModal>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, user, requesting, baseRoute, userData, appRequesting } = state;
  return {
    alert,
    user,
    requesting,
    baseRoute,
    userData,
    appRequesting,
  };
}

export default connect(mapStateToProps)(LoginPage);
